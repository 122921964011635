import { template as template_d88fdd9b62694554a8dd254cb0d1a60a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d88fdd9b62694554a8dd254cb0d1a60a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
