import { template as template_1f5c9e7e28754d68b7b8b44d4b827844 } from "@ember/template-compiler";
const FKText = template_1f5c9e7e28754d68b7b8b44d4b827844(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
